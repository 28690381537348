import { Facebook, Instagram, MailOutlineOutlined, Phone, Pinterest, Room, Twitter, WhatsApp } from '@mui/icons-material'
import React from 'react'
import { styled } from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`

const Left = styled.div`
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    flex: 1;
    text-align: left;
  }
`

const Logo = styled.h1`
  font-weight: bold;
  color: white;
`

const Description = styled.p`
  margin: 20px 0;
`

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`

const Center = styled.div`
  padding: 20px;
`

const Title = styled.h3`
  margin-bottom: 20px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`

const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
`

const Right = styled.div`
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    flex: 1;
    text-align: left;
  }
`

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`

const Payment = styled.img`
  width: 50%;
`

const Footer = () => {
  const handleWhatsApp = () => {
    const whatsappURL = `https://wa.me/+971503402009`;
    window.open(whatsappURL, "_blank");
  }

  const handleFacebook = () => {
    const facebookURL = `https://www.facebook.com/gnsrealestateuae/about`;
    window.open(facebookURL, "_blank");
  }

  const handleInstagram = () => {
    const instagramURL = `https://www.instagram.com/gns.realestate/`;
    window.open(instagramURL, "_blank");
  }

  const handleTwitter = () => {
    const twitterURL = `https://twitter.com/GnsRealEstate`;
    window.open(twitterURL, "_blank");
  }

  const handleMail = () => {
    const mailURL = `mailto:info@gnsre.com`;
    window.open(mailURL, "_blank");
  }

  return (
    <Container>
      <Left>
        <Logo>GNS</Logo>
        <Description>We provide 24/7 customer support to ensure that our clients have access to support and assistance at any time of day or night. This service includes a dedicated customer support team that is available around the clock to answer questions, provide advice, and assist with any issues or concerns that clients may have</Description>
        <SocialContainer>
          <SocialIcon onClick={handleFacebook}>
            <Facebook />
          </SocialIcon>
          <SocialIcon onClick={handleInstagram}>
            <Instagram />
          </SocialIcon>
          <SocialIcon onClick={handleTwitter}>
            <Twitter />
          </SocialIcon>
          <SocialIcon onClick={handleMail}>
            <MailOutlineOutlined />
          </SocialIcon>
          <SocialIcon onClick={handleWhatsApp}>
            <WhatsApp />
          </SocialIcon>
        </SocialContainer>
      </Left>
      <Center>
        <Title>Useful Links</Title>
        <List>
          <ListItem>Home</ListItem>
          <ListItem>About</ListItem>
          <ListItem>Service</ListItem>
          <ListItem>Contact Us</ListItem>
          <ListItem>Accessories</ListItem>
          <ListItem>My Account</ListItem>
          <ListItem>Wishlist</ListItem>
          <ListItem>Terms</ListItem>
        </List>
      </Center>
      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <Room style={{ marginRight: "10px" }} />
          Office No.1, NBQ Building, Bank Street, Near Burjuman, Bur Dubai, Dubai, United Arab Emirates
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} />
          +971509474006, P.O.Box. 242653, Dubai - UAE
        </ContactItem>
        <ContactItem>
          <MailOutlineOutlined style={{ marginRight: "10px" }} />
          info@gnsre.com
        </ContactItem>
      </Right>
    </Container>
  )
}

export default Footer;
