import React from "react";
import "./Service.css";
import Header from "../../component/header/Header";
import Navbar from "../../component/navbar/nav";
import Footer from "../../component/footer/footer";
import q1 from "../../images/q1.jpg";
import q2 from "../../images/q2.jpg";
import q3 from "../../images/q3.jpg";
import q4 from "../../images/q4.jpg"; 
import q5 from "../../images/q5.jpg";
import Announcement from "../../component/Announcement";


const Service = () => {
  return (
    <>
    <Announcement/>
      <Navbar />
      <div className="service-page-container">
        <div className="service-section left-section">
          <div className="content">
            <div className="text">
              <h3>Resident Sales And Leasing</h3>
              <p>Residential sales and leasing services refer to the
                services provided by a real estate agent or broker to
                clients who are interested in buying, selling, or leasing
                residential properties. These services may include:
                Property valuation, listing services, Property search,
                and Property viewings, Negotiation, Documentation
                and Closing. </p>
            </div>
            <img src={q1} alt="placeholder" />
          </div>
          <hr />
          <div className="content">
            <div className="text">
              <h3>OFF PLAN PROPERTIES</h3>
              <p>Off-plan properties refer to properties that are sold by
                developers before they are constructed or completed.
                Off-plan properties are often available at a lower price
                than completed properties, and buyers can benefit
                from the potential appreciation in value as the property
                is constructed and completed</p>
            </div>
            <img src={q2} alt="placeholder" />
          </div>
          <div className="content">
            <div className="text">
              <h3>PROPERTY MANAGEMENT</h3>
              <p>Property management services refer to the services
                provided by a professional property manager to
                oversee and manage residential or commercial properties on behalf of property owners
              </p>
            </div>
            <img src={q3} alt="placeholder" />
          </div>
        </div>
        <div className="separator"></div>
        <div className="service-section right-section">
          <div className="content">
            <img src={q4} alt="placeholder" />
            <div className="text">
              <h3>Commerical Sales and Leasing.</h3>
              <p>Commercial sales and leasing services refer to the
                services provided by a real estate agent or broker to
                clients who are interested in buying, selling, or leasing
                commercial properties</p>
            </div>
          </div>
          <hr />
          <div className="content">
            <img src={q5} alt="placeholder" />
            <div className="text">
              <h3>PROJECT SALES AND MARKETING</h3>
              <p>Project sales and marketing services refer to the
                services provided by a real estate agency or broker to
                developers who are launching a new real estate project</p>
            </div>
          </div>
          <div className="content">
            <img src={q5} alt="placeholder" />
            <div className="text">
              <h3>MORTGAGE & FINANCE ASSISTANCE</h3>
              <p>Mortgage and finance assistance services refer to the
                services provided by a real estate agent or broker to
                clients who are interested in buying a property and
                need help with securing a mortgage or financing.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="space-around">
                    </div>
      <Footer />
    </>
  );
};

export default Service;
