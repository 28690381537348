import React from "react";
import { useState } from "react";
import "./About.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../../component/navbar/nav";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/footer";
import v1 from "../../video/v1.mp4";
import Announcement from "../../component/Announcement";
import emar from '../../images/emaar.jpg';
import Binghahtti from '../../images/l7.jpg';
import Damac from '../../images/l2.jpg';
import LIV from '../../images/l4.jpg';
import octa from '../../images/l3.jpg';
import R from '../../images/l8.jpg';
import Sobha from '../../images/a1.jpg';
import town from '../../images/l6.jpg';
import arada from "../../images/l1.jpg";


const About = () => {

    const developer = [
        { n: "DAMAC", img: Damac },
        { n: "L.I.V", img: LIV },
        { n: "R.", img: R },
        { n: "SOBHA", img: Sobha },
        { n: "TownX", img: town },
        { n: "ARADA", img: arada },
        { n: "octa", img: octa },
        { n: "BINGHATTI", img: Binghahtti },
        { n: "EMAAR", img: emar }
    ];
    
return (
    <>
        <Announcement />
        <Navbar />
        <div className="about-container">

            <section id="mission" className="about-section">
                <h2>Mission</h2>
                <p> We aim to create a culture of excellence and innovation, where our
                    team of experienced professionals is empowered to think creatively
                    and find new ways to deliver value to our clients. Our vision is to
                    build lasting relationships with our clients, based on trust, transparency, and mutual respect.
                    We aspire to be a trusted partner for our clients, providing them
                    with unparalleled expertise, knowledge, and insights that help
                    them make informed decisions about their real estate investments.
                    Our goal is to be recognized as the most reliable and trustworthy
                    real estate company in Dubai, seitng the standard for excellence in
                    the industry</p>
            </section>
            <section id="vision" className="about-section">
                <h2>Vision</h2>
                <p>our vision is to be the leading real estate company in Dubai, recognized for our commitment to excellence, innovation, and client satisfication
                </p>
            </section>

            <section id="developer" className="deve-section">
    <h2>Our Developers</h2>
    <div className="developer-container">
        {Array.from({ length: Math.ceil(developer.length / 3) }, (_, index) => (
            <div className="developer-row" key={index}>
                {/* For each row, render three developer items */}
                {developer.slice(index * 3, index * 3 + 3).map((dev, idx) => (
                    <div className="developer-item" key={idx}>
                        <img src={dev.img} alt={dev.n} className="developer-img" />
                        <div className="developer-title">
                            <h3>{dev.n}</h3>
                        </div>
                    </div>
                ))}
            </div>
        ))}
    </div>
</section>



            <section id="video" className="about-section">
                <h2>Video</h2>
                <div className="video-container">
                    <video controls>
                        <source src={v1} type="video/mp4" className="video_tag" />
                    </video>
                </div>
            </section>


            <section id="why-choose-me" className="about-section">
                <h2>Why Choose Me</h2>
                <div className="choose-me-content">
                    <h3>--TRAINED PROFESSIONAL</h3>
                    <p>Having trained professionals as a company value is an important aspect of any successful real estate business. Trained
                        professionals bring a wealth of knowledge and expertise to the industry, which can help to improve the quality of
                        service provided to clients, increase sales and revenue, and enhance the overall reputation of the company.
                        Trained professionals in the real estate industry may have a range of qualifications, such as degrees or certifications in
                        real estate, finance, marketing, or business management. They may also have completed training programs or appren-
                        ticeships to gain practical experience in the field</p>
                    <h3>--EXCLUSIVE PROJECTS AND PARTNERSHIPS</h3>
                    <p>Exclusive projects and partnerships refer to the real estate services provided by a company that specializes in offering
                        unique and exclusive properties to clients. These properties may be rare, high-end, luxury, or otherwise exclusive in
                        nature, and may not be available through traditional real estate channels.
                        Exclusive projects and partnerships may involve working closely with developers or other real estate professionals to
                        offer exclusive properties or access to properties that are not available on the open market. This may include off-market listings, pre-construction properties, or other exclusive opportunities.</p>
                    <h3>--ONE STOP SOLUTION</h3>
                    <p>
                        A one-stop solution refers to a comprehensive and all-inclusive approach to providing services that meet all of a client's
                        needs in a particular industry or area of business. In the real estate industry, a one-stop solution provider offers a range
                        of services that cover all aspects of the buying, selling, leasing, and managing of properties.
                    </p>

                </div>
            </section>

        </div>
        <div style={{backgroundColor:"black" , color:"white" } }>
        <Footer />
        </div>
    </>
);
};

export default About;
