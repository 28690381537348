import "./loveproperties.css";
import usefetch from "../../hooks/usefetch.js";
import { useState } from "react";

const LoveProperties = () => {

    const { data, loading } = usefetch("https://tiny-pink-harp-seal-boot.cyclic.app/api/hotels?featured=true");

    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const imagesPerPage = 2;


    const [selectedHotel, setSelectedHotel] = useState(null); // State for selected hotel

    const handleImageHover = (hotel) => {
        setSelectedHotel(hotel); // Update selected hotel state
    };

    const closeModal = () => {
        setSelectedHotel(null); // Reset selected hotel state
    }

    const handleNext = () => {
        if (currentPage < Math.ceil(data.length / imagesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            <h2 style={{
  fontSize: "40px",
  fontFamily: "Dancing Script, cursive",
  marginBottom: "20px",
  textAlign: "center"
  
}}>FUTURE PLAN</h2>

            <div className="containor">
                <div className="fp">
                    {loading ? "loading" : (
                        <>
                            {data.slice(
                                (currentPage - 1) * imagesPerPage,
                                currentPage * imagesPerPage
                            ).map((item, i) => (
                                <div className="fpitem" key={item._id} onMouseEnter={() => handleImageHover(item)} onMouseLeave={closeModal}>
                                    <img
                                        src={item.photos[0]}
                                        alt=""
                                        className="fpimg"
                                    />
                                    {selectedHotel && selectedHotel._id === item._id && (
                                        <div className="hotel-details">
                                            <h2>{selectedHotel.Hotel_name}</h2>
                                            <h2>{selectedHotel.name}</h2>
                                            <p><b>City</b>: {selectedHotel.city}</p>
                                            <p><b>Type</b>: {selectedHotel.type}</p>
                                            <p><b>AED </b> {selectedHotel.cheapest}</p>
                                            <div className="rating">
                                                <button>{selectedHotel.rating}</button>
                                                <span>Excellent</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className="navigation">
                                <button onClick={handlePrevious} disabled={currentPage === 1}>
                                    <fontAwesomeIcon icon="chevron-left" /> {/* Font Awesome icon */}
                                </button>
                                <button onClick={handleNext} disabled={currentPage >= Math.ceil(data.length / imagesPerPage)}>
                                    <fontAwesomeIcon icon="chevron-right" /> {/* Font Awesome icon */}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            
        </>
    )
}

export default LoveProperties;
