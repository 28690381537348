import "./Header.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa0, faBed, faCalendarDays, faCar, faContactBook, faHome, faPerson, faPlane, faSearch, faTaxi, faUser, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons'
import { DateRange } from 'react-date-range';
import { useContext, useEffect, useState, usedates } from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ContextSearch } from "../../Context/ContextSearch";
import { AuthSearch } from "../../Context/AuthContext";


const Header = ({ type }) => {

  const [desitination, setdestination] = useState("");
  const [Future, setFuture] = useState("");

  // For date search bhar
  const [openDate, setopendate] = useState(false);
  const [dates, setdates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  // for regsiter aduilt and children
  const [openoption, setopenoption] = useState(false);
  const [options, setoption] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });

  const navigate = useNavigate();

  const handle = (name, operation) => {
    setoption(prev => {
      return {
        ...prev, [name]: operation === "I" ? options[name] + 1 : options[name] - 1,
      };
    })
  }

  const { user } = useContext(AuthSearch);


  const { dispatch } = useContext(ContextSearch);
 


  const handleSearch = () => {
    dispatch({ type: "NEW_SEARCH", payload: { desitination, Future } })
    console.log(desitination, Future);
    navigate("/hotels", { state: { desitination, Future } })
  }


  return (
    <div className="header-container">
    <div className="header">
      <div className={type === "list" ? "listmode" : "headercontainor"}>
        {type !== "list" && <><h1 className="headertitle">PREMIER REAL ESTATE </h1>
         <h1  className="headertitle"> SERVICES BY GNS IN DUBAI</h1>
         <div className="link">
          <a href="https://gnsre.com/">Learn More</a>
         </div>
        </>}


        {type !== "list" && <>
          <div className="headersearch">
            <div className="headersearchitem">
              <FontAwesomeIcon icon={faSearch} className="headericon" />
              <select onChange={e => setdestination(e.target.value)} className="headersearchinpt">
                <option value="">Choose Your Property</option>
                <option value="Town-House">Town-House</option>
                <option value="Apartments">Apartments</option>
                <option value="Villa">Villa</option>
              </select>
            </div>
            <div className="headersearchitem">
              <button className="headerbutton" onClick={handleSearch}>Submit</button>
            </div>
          </div>
          
        </>
        }



      </div>
    </div>
    </div>

  )
}

export default Header
