/* eslint-disable react-hooks/rules-of-hooks */
import "./list.css"
import Navbar from "../../component/navbar/nav";
import Header from "../../component/header/Header";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { format, setDate } from "date-fns";
import { DateRange } from "react-date-range";
import SearchItem from "../../component/search/searchitem";
import Footer from "../../component/footer/footer";
import usefetch from "../../hooks/usefetch.js";
import Names from "../../component/name/name.js";

const List = () => {
  const [openDate, setopendate] = useState(false);
  const location = useLocation();
  const [desitination, setdestination] = useState(location.state.desitination);
  const [dates, setdates] = useState(location.state.dates);
  const [options, setoption] = useState(location.state.options);

  const [check1, setcheck1] = useState(false);
  const [check2, setcheck2] = useState(true);

  const [min, setmin] = useState(undefined);
  const [max, setmax] = useState(undefined);
  const [filteredName, setFilteredName] = useState(null);

  const { data, loading, refetch } = usefetch(`https://tiny-pink-harp-seal-boot.cyclic.app/api/hotels?type=${desitination}`);

  const handleClick = () => {
    refetch();
  };

  const handleNameClick = (name) => {
    console.log(uniqueNames);
    setFilteredName(name);
    setcheck1(true);
    setcheck2(false);
  };

  const uniqueNames = Array.from(
    data.reduce((acc, item) => {
      if (!acc.has(item.name)) {
        acc.set(item.name, { name: item.name, image: item.photos[0] });
      }
      return acc;
    }, new Map()).values()
  );
  

  return (
    <div>
      <Navbar />
      <Header type="list" />
      {check2 && (
        <div className="listContainor">
          <div className="listwrapper">
            <div className="listresult">
              <h2 className="heading">Select the Developer Name</h2>
              {loading ? "loading" : (
                uniqueNames.map(item => (
                  <Names
                    item={item}
                    key={item._id}
                    onClick={handleNameClick}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      )}
      {check1 && (
        <div className="listContainor">
          <div className="listwrapper">
            <div className="listresult">
              {loading ? "loading" : (
                data.map(item => (
                  <SearchItem
                    item={item}
                    key={item._id}
                    name={filteredName}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      )}

            <div style={{backgroundColor:"black" , color:"white" } }>
        <Footer />
        </div>
    </div>
  );
};

export default List;
