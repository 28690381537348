import "./Contact.css";
import Navbar from "../../component/navbar/nav";
import Footer from "../../component/footer/footer";
import Header from "../../component/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Facebook, Instagram, MailOutlineOutlined, Twitter, WhatsApp } from "@mui/icons-material";
import { styled } from 'styled-components'
import Announcement from "../../component/Announcement";

const Contact = () => {
    const handlewhatsapp = () => {
        const whatsappURL = `https://wa.me/+971509474006`;
        window.open(whatsappURL, "_blank");
    }

    const handlefacebook = ()=>{
        const facebookURL = `https://www.facebook.com/GNS-Real-Estate-107287568351639/`;
        window.open(facebookURL, "_blank");
    }

    const handleinstagram = ()=>{
        const instagramURL = `https://www.instagram.com/gnsrealestate/`;
        window.open(instagramURL, "_blank");
    }


    const handletwitter  = ()=>{
        const twitterURL = `https://twitter.com/GnsRealEstate`;
        window.open(twitterURL, "_blank");
    }

    const handlemail = ()=>{
        const mailURL = `mailto:info@gnsre.com`;
        window.open(mailURL, "_blank");
    }

    return (
        <>
        <Announcement/>
            <Navbar />
            {/* <Header /> */}
            <div className="contact-heading">
                <h1>Contact</h1>
                <div className="contact-info">
                    <p><strong>LinkedIn:</strong><a href="https://www.linkedin.com/company/gns-realestate-llc/">https://www.linkedin.com/company/gns-realestate-llc/</a></p>
                    <p><strong>Instagram:</strong><a href="https://www.instagram.com/gnsrealestate/">https://www.instagram.com/gnsrealestate/</a></p>
                    <p><strong>Facebook:</strong><a href="https://www.facebook.com/gnsrealestateuae/">https://www.facebook.com/gnsrealestateuae/</a></p>
                    <p><strong>Phone:</strong> <a href="tel:+971509474006">+971 50 947 4006</a></p>
                    <p><strong>Email:</strong> <a href="mailto:info@gnsre.com">info@gnsre.com</a></p>
                    <p><strong>Address:</strong> Office No.1, NBQ Building, Bank Street, Near Burjuman, Bur Dubai, Dubai, United Arab Emirates </p>
                    <div className="icon-heading">
                        <div onClick={handlefacebook}>
                            <Facebook/>
                        </div>
                        <div onClick={handleinstagram}>
                            <Instagram/>
                        </div>
                        <div onClick={handletwitter}>
                            <Twitter/>
                        </div>
                        <div onClick={handlemail}>
                            <MailOutlineOutlined/>
                        </div>
                        <div onClick={handlewhatsapp}>
                            <WhatsApp/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{backgroundColor:"black" , color:"white" } }>
        <Footer />
        </div>
        </>
    );
};

export default Contact;
