/* eslint-disable no-undef */
import "./Hotel.css"

import Navbar from "../../component/navbar/nav";
import Header from "../../component/header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowLeft, faCircleArrowRight, faCircleXmark, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import Mail from "../../component/mail/mail";
import Footer from "../../component/footer/footer";
import React, { useContext, useState } from "react";
import usefetch from "../../hooks/usefetch.js";
import { useLocation, useNavigate } from "react-router-dom";
import { ContextSearch } from "../../Context/ContextSearch";
import { AuthSearch } from "../../Context/AuthContext";

import Reserve from "../../component/reserve/reserve";
import { WhatsApp } from "@mui/icons-material";

const Hotel = () => {
  // describe  the place of location when we click the button
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [slidenumber, setslidenumber] = useState(0);

  const [open, setopen] = useState(false);


  const { data, loading } = usefetch(`https://tiny-pink-harp-seal-boot.cyclic.app/api/hotels/find/${id}`);

  console.log("data", data);

  // console.log(dates);
  //substract the starting date from ending dates
  const MILLISECOND_PER_DAY = 1000 * 60 * 60 * 24;
  function daydifference(date1, date2) {
    const timediff = Math.abs(date2?.getTime() - date1?.getTime());
    const diffdays = Math.ceil(timediff / MILLISECOND_PER_DAY);
    return diffdays;
  }

  const handleOpen = (i) => {
    setslidenumber(i);
    setopen(true);
  }


  const handlearrow = (direct) => {
    let newslide;
    if (direct === 'l') {
      newslide = slidenumber === 0 ? 5 : slidenumber - 1;
    }
    else {
      newslide = slidenumber === 5 ? 0 : slidenumber + 1;
    }
    setslidenumber(newslide);
  }



  const { user } = useContext(AuthSearch);
  const navigate = useNavigate();
  const [openmodel, setopenmodel] = useState(false);

  const handleclick = () => {
    navigate("/register")
  }

  const handleclick2 = () => {
    const whatsappURL = `https://wa.me/+971509474006`;
    window.open(whatsappURL, "_blank");

  }

  return (
    <div>
      <Navbar />
      <Header type="list" />
      {loading ? "loading" : (<div className="hotelcantainor">
        {open && <div className="slider" >
          <FontAwesomeIcon icon={faCircleXmark} className="close" onClick={() => setopen(false)} />
          <FontAwesomeIcon icon={faCircleArrowLeft} className="arrow" onClick={() => handlearrow("l")} />
          <div className="sliderwrapping">
            <img src={data.photos[slidenumber]} alt="" className="sliderbar" />
          </div>
          <FontAwesomeIcon icon={faCircleArrowRight} className="arrow" onClick={() => handlearrow("r")} />
        </div>}

        <div className="hotelwrapper">
          <button className="booknow">Reserve or Book Now</button>
          <h1 className="hoteltittle">{data?.Hotel_name}</h1>
          <div className="hoteladdress">
            < FontAwesomeIcon icon={faLocationDot} />
            <span>{data.address}</span>
          </div>
          <span className="hoteldistance">Photos</span>
          <div className="hotelimgage">
            <div className="hotelimgage">
              <div className="hotelimgage">
                {data?.photos && data.photos.map((photo, i) => (
                  <div className="hotelimgwrapper" key={i}>
                    <img onClick={() => handleOpen(i)} src={photo} alt="" className="hotelimg" />
                  </div>
                ))}
              </div>

            </div>

          </div>
          <div className="hoteldetails">
            <div className="detailstext">
              <h1 className="hoteltittledesc">Description</h1>
              <p className="hoteldesc" style={{ whiteSpace: 'pre-line' }}>
                {data?.Description && data.Description
                  .replace(/\./g, '.\n') // Add line break after full stop
                }
              </p>
              {data.featured ? (
                <div className="future_place">
                  <h3>Payment Plan</h3>
                  {data.future.map((item, index) => (
                    <React.Fragment key={index}>
                      <span> <b>{item}</b></span>
                      <hr />
                    </React.Fragment>

                  ))}
                </div>
              ) : null}
            </div>
            <div className="hotelpricefinal">
              <h3>Price</h3>
              <h2><b>AED </b><b>{data?.cheapest}</b></h2>
              <button onClick={handleclick}>Reserve or Book Now</button>
              <div onClick={handleclick2} className="whatsappbutton"><WhatsApp/></div>
            </div>
          </div>
        </div>
        {/* <Mail /> */}
        <div style={{backgroundColor:"black" , color:"white" } }>
        <Footer />
        </div>
      </div>)}
    </div>
  )
}

export default Hotel




