import React from 'react';
import { keyframes, styled } from 'styled-components';

const movingAnimation = keyframes`
  0%   {  left: 0; transform: translateX(0); }
  25%  { left: 25%; transform: translateX(25deg); }
  50%  { left: 50%; transform: translateX(50deg); }
  75%  { left: 75%; transform: translateX(75deg); }
  100% {  left: 100%; transform: translateX(100deg); }
`;

const Containor = styled.div`
  top:0;
  width: 100vw;
  height: 30px;
  background-color:  rgb(126, 28, 44);
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
`;

const Valur = styled.span`
  margin: 0px 10px;
  position: relative; 
  animation: ${movingAnimation} 40s linear infinite;
`;

const Announcement = () => {
  return (
    <Containor>
      <Valur>
      Office No.1, NBQ Building, Bank Street, Near Burjuman, Bur Dubai, Dubai, United Arab Emirates
      </Valur>
    </Containor>
  );
};

export default Announcement;
