import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
  } from "react-router-dom";
import Home from "./pages/home/Home";
import List from "./pages/list/List";
import Hotel from "./pages/hotel/Hotel";
import Login from './pages/login/login'; 
import Register from './pages/register/register';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Service from './pages/Service/Service';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element = {<Home/>}/>
        <Route path='/hotels' element = {<List/>}/>
        <Route path='/About' element = {<About/>}/>
        <Route path='/contact' element = {<Contact/>}/>
        <Route path='/service' element = {<Service/>}/>
        <Route path='/hotels/:id' element = {<Hotel/>}/>
        <Route path='/Login' element = {<Login/>}/>
        <Route path='/Register' element = {<Register/>}/>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
