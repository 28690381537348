/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react"
import "./register.css"
import { useNavigate } from "react-router-dom"
import axios from "axios";
import Navbar from "../../component/navbar/nav";
import Footer from "../../component/footer/footer";
import Header from "../../component/header/Header";


const register = () => {

  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [country, setcountry] = useState("");
  const [age, setage] = useState("");
  const [phone, setphone] = useState("");
  const [city, setcity] = useState("");
  const [message, setmessage] = useState("");

  
    const handlechange = (e) => {
        if (e.target.id === "name") {
            setname(e.target.value);
        }
        else if (e.target.id === "Email") {
            setEmail(e.target.value);
        }
        else if (e.target.id === "country") {
            setcountry(e.target.value);
        }
        else if (e.target.id === "age") {
            setage(e.target.value);
        }
        else if (e.target.id === "phone") {
            setphone(e.target.value);
        }
        else if (e.target.id === "city") {
            setcity(e.target.value);
        }
        else if (e.target.id === "message")
        {
          setmessage(e.target.value);
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
  

    const handleclick = async (e) => {
        e.preventDefault();


        try {
          const response = await axios.post('https://tiny-pink-harp-seal-boot.cyclic.app/api/auth/register', {
            name: name,
            Email: Email,
            phone: phone,
            city : city,
            message:message
          });

          if (response.status === 200) {
            alert("Registration Successfull Our Team will contact you soon");
            navigate("/")
          }

    }catch(error)
    {
        alert(error)
    }
  }

  return (
    <>
    <Navbar/>
    <div className="regsiter">
        <div className="regcontainor">
       
            <input type="text" placeholder="Name" id="name" className="rinput"  onChange={handlechange}/>
            <input type="email"placeholder="Email" id="Email" className="rinput" onChange={handlechange}/>
           
            
            <input type="phone" placeholder="Phone number" id="phone" className="rinput"  onChange={handlechange}/>
            <input type="city"placeholder="City" id="city" className="rinput" onChange={handlechange}/>
           
            <textarea placeholder="Enter your Requirment" id="message" className="rinput" rows={10} cols={50} onChange={handlechange}/>
            <button className="rbutton" onClick={handleclick}>Register</button>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default register
