import { useState } from "react";
import { Link } from "react-router-dom";
import SearchItem from "../../component/search/searchitem";
import "./name.css";

const Names = ({ item, onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    console.log(item.name);
    onClick(item.name);
  };

  return (
    <div className={`nameitem ${item.soldout ? "soldOut" : ""}`}>

      {!clicked && (
        <div className="nameitem-container">
          <div className="deveimg">
            <img
              src={item.image}
              alt=""
              className="deveimg"
            />
          </div>
          <div className="nameitem-content">
            <h1 className="nametitle" onClick={handleClick}>
              {item.name}
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default Names;
