import { useContext, useState } from "react";
import "./nav.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthSearch } from "../../Context/AuthContext";
import logo from "../../images/lg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faContactBook, faHome, faObjectGroup, faRegistered, faUser, faUsersViewfinder, faBars, faTimes, faUserGroup } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const { user } = useContext(AuthSearch);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [userType, setUserType] = useState(true);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
    setUserType(!userType);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    document.cookie = "access_token = ; expires = Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/");
    window.location.reload();
  };

  const handleRegister = () => {
    navigate("/Register");
  };


  const handleScrollToTeam = () => {
    navigate("/");
    const teamSection = document.getElementById("Team");
    if (teamSection) {
      teamSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="navbar">
      <div className="navContainor">
        {userType ? <div className="left">
          <div className="headerlist">
            <div className="headeritem" onClick={() => navigate("/")}>
              <FontAwesomeIcon icon={faHome} />
              <span className="change">Home</span>
            </div>
            <div className="headeritem" onClick={handleScrollToTeam}>
              <FontAwesomeIcon icon={faUserGroup} />
              <span className="change">Team</span>
            </div>
          </div>
        </div>  : null}
        <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
          <span className="logo">
            <img src={logo} alt="GNS" />
          </span>
        </Link>
        {userType ? <div className="right">
          <div className="headeritem" onClick={() => navigate("/contact")}>
            <FontAwesomeIcon icon={faContactBook} />
            <span className="change">Contact us</span>
          </div>
        </div>: null}
        <div className="menu-icon" onClick={handleToggleMenu}>
            {menuOpen ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </div>
      {menuOpen && (
        <div className="mobile-menu">
          <div className="headeritem" onClick={() => navigate("/")}>
            <FontAwesomeIcon icon={faHome} />
            <span className="change">Home</span>
          </div>
          <div className="headeritem" onClick={() => navigate("/About")}>
            <FontAwesomeIcon icon={faUser} />
            <span className="change">About Us</span>
          </div>
          <div className="headeritem" onClick={() => navigate("/service")}>
            <FontAwesomeIcon icon={faUsersViewfinder} />
            <span className="change">Service</span>
          </div>
          <div className="headeritem" onClick={() => navigate("/contact")}>
            <FontAwesomeIcon icon={faContactBook} />
            <span className="change">Contact us</span>
          </div>
          <div className="headeritem"   onClick={handleScrollToTeam}>
              <FontAwesomeIcon icon={faUserGroup} />
              <span className="change">Team</span>
            </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default Navbar;
