import "./Home.css";
import Navbar from "../../component/navbar/nav";
import Header from "../../component/header/Header";
import Featured from "../../component/featured/feature";
import Properties from "../../component/propertieslist/properties";
import Loveproperties from "../../component/loveproperties/loveproperties";
import Mail from "../../component/mail/mail";
import Footer from "../../component/footer/footer";
import Announcement from "../../component/Announcement";
import ceo from "../../images/ceo.jpg";
import sal1 from "../../images/sales1.jpg";
import sal2 from "../../images/sales2.jpg";
import sale3 from "../../images/sales3.jpg";
import business from "../../images/business.jpg";
import assitent from "../../images/assisstent.jpg";
import marketing from "../../images/mark.jpg";
import sal from "../../images/sa1.jpg";
import { useRef } from "react";
const Team = [
  { n: "Shafiq Ur Rehman", img: sal, rol: "Sales Manager" },
  { n: "Ameet Kumar", img: sal2, rol: "Sales Manager" },
  { n: "Wajahat Ali", img: sale3, rol: "Sales Manager" },
  { n: "Nisar Ahmed", img: business, rol: "Business Development Manager" },
  { n: "Girish Advani", img: marketing, rol: "Marketing Manager" },
  { n: "Krystelle Serrano", img: assitent, rol: "Representative Assistant" },
];

const Home = () => {
  const teamRef = useRef(null);

  const scrollToTeam = () => {
    teamRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Announcement />
      <Navbar />
      <Header />
      <div className="homecantainor">
        {/* <Featured /> */}
        <section id="Team" ref={teamRef} className="team-section">
        <h2 style={{textDecoration:"underline" , fontSize:"50px"}}>Our Team</h2>
        <div className="senior">
          <div className="ceo-img">
            <h2>Vijay Sakhija </h2>
            <h2>(CEO)</h2>
            <img src={ceo} alt="CEO" className="developer2-img" />
          </div>
          <div className="ceo-img">
          <h2>Manoj Kumar </h2>
          <h2>(Senior Property Expert)</h2>
            <img src={sal1} alt="CEO" className="developer-img" />
          </div>
          </div>
          <div className="developer-container">
            {Array.from({ length: Math.ceil(Team.length / 3) }, (_, index) => (
              <div className="developer-row" key={index}>
                {/* For each row, render three developer items */}
                {Team.slice(index * 3, index * 3 + 3).map((dev, idx) => (
                  <div className="developer-item" key={idx}>
                    <img src={dev.img} alt={dev.n} className="developer-img" />
                    <div className="developer-title">
                      <h2>{dev.n}</h2>
                      <p>{dev.rol}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </section>
        <Properties />
        <Loveproperties />
        {/* <Mail /> */}
        <div style={{backgroundColor:"black" , color:"white" } }>
        <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
