import { Link } from "react-router-dom";
import "./searchitem.css";

const SearchItem = ({ item, name }) => {
  // Check if the item's name matches the specified name
  const isNameMatch = item.name === name;

  // Render the component only if the name matches
  return (
    <>
      {isNameMatch && (
        <div className={`searchitem ${item.soldout ? 'soldOut' : ''}`}>
          <img src={item.photos[1]} alt="" className="siImg"/>
          <div className="sidesc" >
            <div className="sititle">
            <h1>{item?.Hotel_name}</h1>
            </div>
            <span className="sisubtitle">Best choice {item.type}.</span>
            <span className="sicancel">Free Cancellation</span>
            <span className="sicancel-subtitle">You can cancel later, so look in the high price</span>
          </div>
          <div className="sidetails">
            {item.rating && <div className="israting">
              <span>Excellent</span>
              <button>{item.rating}</button>
            </div>}
            <div className="isdetailstext">
              <span className="isprice">{item.cheapest}</span>
              {item.soldout ? (
                <span className="soldOutLabel">Sold Out</span>
              ) : (
                <Link to={`/hotels/${item._id}`}>
                  <button className="sibtn">See Availability</button>
                </Link>
              )}
            </div>
          </div>

        </div>
      )}
    </>
  );
};

export default SearchItem;
